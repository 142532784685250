import React from 'react';
import styles from './ChannelSelectionMenu.module.scss';
import { List } from '@yleisradio/areena-types';
import { useTranslation } from 'hooks/useTranslation';
import ChevronDown from 'assets/chevronDown.svg';

interface ChannelSelectionMenuProps {
  onSelect: (selection: string) => void;
  selectedChannel?: string | undefined;
  channels: List[];
}

export const ChannelSelectionMenu = (props: ChannelSelectionMenuProps) => {
  const t = useTranslation();

  return (
    <div className={styles.wrapper}>
      <select
        className={styles.button}
        aria-label={t('selectRegionalChannel')}
        title={t('selectRegionalChannel')}
        value={props.selectedChannel}
        onChange={(e) => {
          const selectedId = e.target.value;
          props.onSelect(selectedId);
        }}
      >
        {props.channels.map((channel) => {
          if (!channel.persistentState?.id) {
            console.warn(
              'Found regional channel without persistentState.id:',
              channel
            );
            return null;
          }

          return (
            <option key={channel.title} value={channel.persistentState.id}>
              {channel.title || ''}
            </option>
          );
        })}
      </select>
      <ChevronDown className={styles.chevronDownIcon} aria-hidden="true" />
    </div>
  );
};
