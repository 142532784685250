import { View } from '@yleisradio/areena-types';
import { Container } from 'components/Container';
import { GridContainer, GridElement } from 'components/Grid';
import ViewMenus from 'components/View/ViewMenus/ViewMenus';
import { useAnalyticsForView } from 'hooks/analytics';
import { isListArray } from 'utils/content';
import { Channel } from './Channel/Channel';
import { SelectableChannel } from './Channel/SelectableChannel';
import styles from './Guide.module.scss';
import { GuideHeader } from './GuideHeader';
import { DatePicker } from './GuideHeader/DatePicker';
import { PastProgramsToggle } from './GuideHeader/PastProgramsToggle';
import { useShowPastPrograms } from './useShowPastPrograms';

interface GuideProps {
  /** Currently selected EPG date (YYYY-MM-DD) */
  currentDate: string;
  /** First available EPG date (YYYY-MM-DD) */
  firstDate: string;
  /** Last available EPG date (YYYY-MM-DD) */
  lastDate: string;
  isFutureDate: boolean;
  isToday: boolean;
  view: View;
}

export const Guide = (props: GuideProps) => {
  const { tabs: channels = [], title, menus } = props.view;
  const [isShowPastProgramsToggledOn, setShowPastProgramsToggle] =
    useShowPastPrograms();

  useAnalyticsForView(props.view.analytics);

  const channelElements = channels.map((channel) => {
    const channelContent = channel.content;

    return (
      channelContent &&
      channelContent.length > 0 &&
      isListArray(channelContent) && (
        <GridElement
          key={channel.title}
          mobile={12}
          mobileLandscape={6}
          desktopSmall={6}
        >
          {channelContent.length > 1 && (
            <SelectableChannel
              isToday={props.isToday}
              isFutureDate={props.isFutureDate}
              showExpired={isShowPastProgramsToggledOn}
              channel={channel}
            />
          )}
          {channelContent.length === 1 && (
            <Channel
              isToday={props.isToday}
              isFutureDate={props.isFutureDate}
              showExpired={isShowPastProgramsToggledOn}
              title={channel.title}
              image={channel.image}
              channelContent={channelContent[0]!}
            />
          )}
        </GridElement>
      )
    );
  });

  return (
    <main id="maincontent" className={styles.root}>
      <Container>
        {menus ? <ViewMenus menus={menus} /> : null}
        <GuideHeader
          title={title}
          datePicker={
            <DatePicker
              currentDate={props.currentDate}
              firstDate={props.firstDate}
              lastDate={props.lastDate}
            />
          }
          pastProgramsToggle={
            props.isToday ? (
              <PastProgramsToggle
                checked={isShowPastProgramsToggledOn}
                toggle={() => {
                  setShowPastProgramsToggle(!isShowPastProgramsToggledOn);
                }}
              />
            ) : null
          }
        />
        <GridContainer gap="small">{channelElements}</GridContainer>
      </Container>
    </main>
  );
};
